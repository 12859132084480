import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import AppBarContainer from 'shared-module/components/app-bar/AppBarContainer'
import useUser from 'user-module/user/core/useUser'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { useCart } from '../../core/cart.hooks'
import CartTotalLine from '../cart-lines/CartTotalLine'
import CartSavingsLine from '../checkout-page/checkout-lines/CartSavingsLine'
import CartContent from './CartContent'
import CartSection from './CartSection'
import DirectPurchaseWrapper from './DirectPurchaseWrapper'
import EmptyCart from './EmptyCart'
import CartButtons from './CartButtons'
import CartBanner from '../cart-items/CartBanner'

const CartPage = () => {
  const { t } = useTranslation()
  const {
    cartItems,
    cartItemsCount,
    isUnavailableItemInCart,
  } = useCart()
  const userClubMembership = useUserClubMembership()
  const user = useUser()

  return user.cartEnabled
    ? (
      <DirectPurchaseWrapper clubMembership={userClubMembership}>
        <AppBarContainer Icon={ShoppingCartIcon} title={t('menu.cart')} />
        <CartBanner isInsidersClubMember={userClubMembership.isMember} />
        { cartItemsCount === 0
          ? <EmptyCart />
          : (
            <>
              <CartSection>
                <CartContent products={cartItems.products} />
                <CartTotalLine />
                <CartSavingsLine />
              </CartSection>
              <CartButtons isUnavailableItemInCart={isUnavailableItemInCart} />
            </>
            ) }
      </DirectPurchaseWrapper>
      )
    : <Redirect to="/" />
}

export default CartPage
