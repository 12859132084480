import 'core-js/stable'
import ReactDOM from 'react-dom'
import App from 'shared-module/App'
import 'shared-module/monitoring/apm'
import theme from 'spypoint/src/shared-module/theme/theme'
import 'spypoint/src/assets/locales/i18n.js'
import 'spypoint/src/assets/fonts/fonts.css'
import Routes from 'spypoint/src/Routes'
import userActions from 'spypoint/src/user-module/user/core/userActions'
import photoActions from 'spypoint/src/photos-module/core/photoActions'
import cameraActions from 'spypoint/src/cameras-module/core/cameraActions'
import packageInfo from '../package.json'

const actions = { userActions: userActions, photoActions: photoActions, cameraActions: cameraActions }

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New content is available refresh.
              window.location.reload()
            }
          }
        }
      }
    }).catch(() => {
    })
  })
}

ReactDOM.render(<App darkTheme={theme} actions={actions} version={packageInfo.version}><Routes /></App>, document.getElementById('root'))
