import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MembershipRenewalSection from 'spypoint/src/user-module/club-membership/ui/header/MembershipRenewalSection'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import HeaderPricingSection from 'spypoint/src/user-module/club-membership/ui/header/HeaderPricingSection'
import JoinClubMembershipSection from 'spypoint/src/user-module/club-membership/ui/header/JoinClubMembershipSection'

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: 'none',
    background: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
}))

const MembershipPageHeader = () => {
  const classes = useStyles()
  const userMembership = useUserClubMembership()

  return (
    <Container maxWidth={false} className={classes.header}>
      <Container maxWidth="lg" disableGutters>
        <Grid container className={classes.container}>
          <HeaderPricingSection />

          <Grid container item sm={5}>
            { userMembership.isMember ? <MembershipRenewalSection /> : <JoinClubMembershipSection /> }
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
export default MembershipPageHeader
