import shopperApi from 'ecommerce-module/api/shopperApi'

const CART_PATH = '/e-commerce/products/cart'

export const fetchBaskets = async (id) => {
  return await shopperApi.getBasket(id)
    .then((response) => response)
    .catch((error) => console.error('Error fetching basket:', error))
}

export const deleteBasket = async (id) => {
  return await shopperApi.deleteBasket(id)
}

export const createBasket = async () => {
  return await shopperApi.createBasket()
    .then((response) => response)
    .catch((error) => console.error('Error creating basket:', error))
}

export const addItemToBasket = async (id, item) => {
  await shopperApi.addItemToBasket(id, item)
    .then((response) => response)
    .catch((error) => console.error('Error adding items to basket:', error))
}

export const deleteItemBasket = async (id, itemId) => {
  return await shopperApi.deleteItemFromBasket(id, itemId)
}

export const navigateToCart = (history) => {
  if (history.location.pathname !== CART_PATH) history.push(CART_PATH)
}
