import { Route, Switch } from 'react-router-dom'
import MobileAppSplashScreen from 'shared-module/download-app/MobileAppSplashScreen'
import SpypointPrivateRoute from 'spypoint/src/private-route/SpypointPrivateRoute'
import CameraSettingsPage from 'camera-module/camera/ui/camera-settings-page/CameraSettingsPage'
import CameraPlan from 'checkout-module/checkout/ui/transmission-plans-page/Plan'
import CameraDashboard from 'photo-module/photos/ui/photos-gallery/Dashboard'
import RedeemPromoCodesPage from 'user-module/promo-code/ui/redeem-promo-codes/RedeemPromoCodesPage'
import SpypointAppBar from 'spypoint/src/shared-module/navigation/app-bar/SpypointAppBar'
import Head from 'shared-module/new-components/head/Head'
import { useTranslation } from 'react-i18next'
import NewCameraForm from 'spypoint/src/cameras-module/NewCameraForm'
import PhotoGalleryTopBar from 'spypoint/src/photos-module/photos/ui/photos-gallery/PhotoGalleryTopBar'
import MembershipPage from 'spypoint/src/user-module/club-membership/ui/MembershipPage'
import Order from 'checkout-module/order/Order'
import CartPage from 'spypoint/src/checkout-module/order/ui/cart-page/CartPage'
import CheckoutPage from 'spypoint/src/checkout-module/order/ui/checkout-page/CheckoutPage'
import SpypointPhotoContainer from './photos-module/photos/ui/photo-viewer/SpypointPhotoContainer'
import SpypointPhotoViewerContainer from './photos-module/photos/ui/photo-viewer/SpypointPhotoViewerContainer'
import SpypointViewerActionsBar from './photos-module/photos/ui/SpypointViewerActionsBar'
import SpypointCamerasPage from 'cameras-module/ui/cameras-page/SpypointCamerasPage'
import CameraListPanel from 'cameras-module/ui/cameras-page/CameraListPanel'
import SharedCameraListPanel from 'cameras-module/ui/cameras-page/SharedCameraListPanel'
import SpypointCameraStatusPage from 'cameras-module/ui/camera-status-page/SpypointCameraStatusPage'
import SpypointAccountPage from 'spypoint/src/user-module/account/SpypointAccountPage'
import SpypointFiltersBar from './photos-module/filters/ui/SpypointFiltersBar'
import ProfilePanel from 'spypoint/src/user-module/account/profile/ProfilePanel'
import PlansAndSubscriptionPanel from 'spypoint/src/user-module/account/plan-and-subscription/PlansAndSubscriptionPanel'
import PaymentPanel from 'spypoint/src/user-module/account/payment/PaymentPanel'
import ManagementPanel from 'user-module/account/management/ManagementPanel'
import ExternalPageContainer from 'user-module/user/ui/ExternalPageContainer'
import LoginPage from 'user-module/user/ui/login/LoginPage'
import RegisterPage from 'user-module/user/ui/register/RegisterPage'
import ResetPasswordPage from 'user-module/user/ui/reset-password/ResetPasswordPage'
import ChangePasswordPage from 'user-module/user/ui/change-password/ChangePasswordPage'
import SpypointPublicPageLayout from 'spypoint/src/shared-module/ui/public-page/SpypointPublicPageLayout'
import INSTRUCTION_TYPES from 'user-module/user/core/instructionTypes'
import PublicRoute from 'shared-module/public-route/PublicRoute'
import SpypointAddCameraPage from './cameras-module/ui/cameras-page/SpypointAddCameraPage'
import CameraActivationPanel from 'camera-module/new-activation/ui/CameraActivationPanel'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import ActivationConfirmationPanel from 'camera-module/new-activation/ui/ActivationConfirmationPanel'
import ECommerceSessionWrapper from 'ecommerce-module/ui/ECommerceSessionWrapper'
import ProductsListing from 'ecommerce-module/ProductsListing'
import CameraSharingPage from 'camera-module/camera/ui/camera-sharing-page/CameraSharingPage'
import CamerasPanel from 'camera-module/camera/ui/camera-sharing-page/CamerasPanel'
import GuestPanel from 'camera-module/camera/ui/camera-sharing-page/guest-panel/GuestPanel'
import SharedCameraPage from 'spypoint/src/shared-camera-module/SharedCameraPage'
import BasketPage from 'spypoint/src/ecommerce-module/basket-page/BasketPage'
import { BasketProvider } from 'spypoint/src/ecommerce-module/BasketContext'

const Routes = () => (
  <Switch>
    <PublicRoute
      exact
      path="/login"
      component={ExternalPageContainer}
      Layout={SpypointPublicPageLayout}
      Content={<LoginPage />}
      instructionTypes={INSTRUCTION_TYPES.LOGIN}
    />
    <PublicRoute
      exact
      path="/:lng/login/:instruction"
      component={ExternalPageContainer}
      Layout={SpypointPublicPageLayout}
      Content={<LoginPage />}
      instructionTypes={INSTRUCTION_TYPES.LOGIN}
    />
    <PublicRoute
      exact
      path="/register"
      component={ExternalPageContainer}
      Layout={SpypointPublicPageLayout}
      Content={<RegisterPage isComplexPassword={false} />}
      instructionTypes={INSTRUCTION_TYPES.REGISTER}
    />
    <PublicRoute
      exact
      path="/:lng/register/:instruction"
      component={ExternalPageContainer}
      Layout={SpypointPublicPageLayout}
      Content={<RegisterPage isComplexPassword={false} />}
      instructionTypes={INSTRUCTION_TYPES.REGISTER}
    />
    <PublicRoute
      exact
      path="/reset-password"
      component={ExternalPageContainer}
      Layout={SpypointPublicPageLayout}
      Content={<ResetPasswordPage />}
    />
    <PublicRoute
      exact
      path="/reset-password/:token"
      component={ExternalPageContainer}
      Layout={SpypointPublicPageLayout}
      Content={<ChangePasswordPage isComplexPassword={false} />}
    />
    <Route exact path="/download-app" component={MobileAppSplashScreen} />
    <SpypointPrivateRoute path="/" component={PrivateRoutes} />
  </Switch>
)

const PrivateRoutes = () => {
  const { t } = useTranslation()
  const cameraActivationEnabled = appConfig.cameraActivation.switch === switches.on
  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
  const sharedCameraEnabled = appConfig.sharedCamera?.switch === switches.on

  return (
    <>
      <Head title={t('app:head.title')} />
      <SpypointAppBar />
      <Switch>
        <SpypointPrivateRoute exact path="/camera/plan" component={SpypointCamerasPage} />
        <SpypointPrivateRoute exact path="/camera/:id/status" component={SpypointCameraStatusPage} />
        <SpypointPrivateRoute exact path="/camera/:id/settings" component={CameraSettingsPage} />
        <SpypointPrivateRoute exact path="/camera/:id/plan" component={ECommerceSessionWrapper} Content={CameraPlan} environment="008" />
        <SpypointPrivateRoute
          path="/camera/:id"
          component={CameraDashboard}
          TopBar={PhotoGalleryTopBar}
          FiltersBar={SpypointFiltersBar}
          PhotoViewerActionsBar={SpypointViewerActionsBar}
          PhotoViewerContainer={SpypointPhotoViewerContainer}
          PhotoContainer={SpypointPhotoContainer}
        />
        <SpypointPrivateRoute path="/account" component={SpypointAccountPage} />
        <SpypointPrivateRoute exact path="/account/profile" component={ProfilePanel} />
        <SpypointPrivateRoute exact path="/account/plans-and-subscriptions" component={PlansAndSubscriptionPanel} />
        <SpypointPrivateRoute exact path="/account/payment" component={PaymentPanel} />
        <SpypointPrivateRoute exact path="/account/management" component={ManagementPanel} />
        <SpypointPrivateRoute exact path="/membership" component={MembershipPage} />
        <SpypointPrivateRoute exact path="/promo-codes" component={RedeemPromoCodesPage} />
        <SpypointPrivateRoute exact path="/order" component={Order} />
        <SpypointPrivateRoute exact path="/cart" component={CartPage} />
        <SpypointPrivateRoute path="/camera-sharing" component={CameraSharingPage} />
        <SpypointPrivateRoute exact path="/camera-sharing/guests" component={GuestPanel} />
        <SpypointPrivateRoute exact path="/camera-sharing/cameras" component={CamerasPanel} />
        <SpypointPrivateRoute exact path="/checkout" component={CheckoutPage} />

        { eCommerceEnabled && <SpypointPrivateRoute exact path="/e-commerce/products" component={ECommerceSessionWrapper} Provider={BasketProvider} Content={ProductsListing} environment="008" /> }
        { eCommerceEnabled && <SpypointPrivateRoute exact path="/e-commerce/products/cart" component={ECommerceSessionWrapper} Provider={BasketProvider} Content={BasketPage} environment="008" /> }

        { /* TODO: REMOVE FEATURE SWITCH ONCE READY FOR PRODUCTION */ }
        { cameraActivationEnabled
          ? <SpypointPrivateRoute exact path="/add-camera" component={SpypointAddCameraPage} />
          : (
            <SpypointPrivateRoute
              exact
              path="/add-camera"
              key="add"
              component={SpypointCamerasPage}
              NewCameraForm={NewCameraForm}
              addCamera
            />
            ) }

        { /* TODO: REMOVE FEATURE SWITCH ONCE READY FOR PRODUCTION */ }
        { cameraActivationEnabled && <SpypointPrivateRoute path="/camera-activation" component={ECommerceSessionWrapper} Content={CameraActivationPanel} environment="008" /> }

        { /* TODO: REMOVE FEATURE SWITCH ONCE READY FOR PRODUCTION */ }
        { cameraActivationEnabled && <SpypointPrivateRoute path="/confirm-activation" component={ActivationConfirmationPanel} /> }
        { sharedCameraEnabled && <SpypointPrivateRoute path="/" component={SharedCameraPage} /> }
        { sharedCameraEnabled && <SpypointPrivateRoute exact path="/camera-list" component={CameraListPanel} /> }
        { sharedCameraEnabled && <SpypointPrivateRoute exact path="/shared-cameras" component={SharedCameraListPanel} /> }

        { !sharedCameraEnabled && <SpypointPrivateRoute path="/" key="list" component={SpypointCamerasPage} NewCameraForm={NewCameraForm} /> }
      </Switch>
    </>
  )
}

export default Routes
