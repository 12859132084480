import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { useReloadCameras } from 'camera-module/cameras/core/useCameras'
import useCamerasActions from 'camera-module/cameras/core/useCamerasActions'
import messageActions from 'shared-module/message/messageActions'
import BigCheckIcon from 'assets/icons/navigation/BigCheckIcon'
import cameraActions from 'camera-module/camera/core/cameraActions'
import filtersState from 'photo-module/filters/core/filtersState'
import useQueryParams from 'shared-module/url/useQueryParams'
import useFreeTrialActivationModal from 'camera-module/free-trial-activation-modal/core/useFreeTrialActivationModal'
import CameraTile from 'camera-module/cameras/ui/cameras-page/CameraTile'
import { useCameraPlansActions } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'
import NewCameraForm from 'spypoint/src/cameras-module/NewCameraForm'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import ActivateCameraTile from 'camera-module/cameras/ui/cameras-page/ActivateCameraTile'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import SpypointPlansDialog from './SpypointPlansDialog'
import { useHistory } from 'react-router-dom'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import useUser from 'user-module/user/core/useUser'
import ConsentFormDialog from '../../ConsentFormDialog'
import cameraSharingActions from 'camera-module/camera/core/CameraSharingActions'
import useSharedCameras from 'camera-module/camera/core/useSharedCameras'
import useFetchingSharedCameras from 'camera-module/camera/core/useFetchingSharedCameras'
import useFetchingPendingSharedCameras from 'camera-module/camera/core/useFetchingPendingSharedCameras'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1.25rem',
    paddingRight: 0,
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.125rem',
      marginLeft: '-0.5rem',
    },
  },
  addButton: {
    minWidth: 'auto',
    whiteSpace: 'noWrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      padding: '0.25rem 0.5rem',
    },
  },
  paperWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  paperNoCamera: {
    marginTop: '50px',
    padding: '20px',
    margin: '110px 20px 110px 20px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  content: {
    width: '100%',
  },
  noCameraMessage: {
    padding: '20px',
    color: theme.palette.text.primary,
    textAlign: 'left',
    fontSize: '16px',
  },
}))

const CameraListPanel = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useUser()
  const { consent } = user
  const FetchingSharedCameras = useFetchingSharedCameras()
  const FetchingPendingSharedCameras = useFetchingPendingSharedCameras()
  const [isOpenAddCameraDialog, setIsOpenAddCameraDialog] = useState(false)
  const [initialAddCameraDialog, setInitialAddCameraDialog] = useState(false)
  const [isOpenConsentDialog, setIsOpenConsentDialog] = useState(false)
  const [cameraId, setCameraId] = useState(null)
  const queryParams = useQueryParams()
  const dialogActions = useConfirmationDialogActions()
  const { cameraHasFreeTrial, openFreeTrialActivationModal } = useFreeTrialActivationModal()
  const cameraPlansActions = useCameraPlansActions()
  const history = useHistory()
  const cameras = useReloadCameras()
  const camerasActions = useCamerasActions()
  const sharedCameras = useSharedCameras()

  const cameraActivationEnabled = appConfig.cameraActivation.switch === switches.on

  const pageTitle = queryParams.transmission_plan
    ? t('app:purchasing_flow.transmission_plan.select_device')
    : t('menu.select_camera')

  const returnToPlans = () => history.push(`/camera/${cameraId}/plan`)

  useEffect(() => {
    dispatch(cameraActions.clear())
    dispatch(cameraPlansActions.reset())
    dispatch(filtersState.clear())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(async () => {
    await dispatch(cameraSharingActions.fetchPendingSharedCameras())
    await dispatch(cameraSharingActions.fetchAllSharedCameras())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!FetchingSharedCameras && !FetchingPendingSharedCameras && !!cameras) {
      if (sharedCameras?.length > 0 && cameras?.length === 0) history.push('/')
      else if (cameras?.length === 0 && sharedCameras?.length === 0) history.push('/add-camera')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameras, sharedCameras, FetchingSharedCameras, FetchingPendingSharedCameras])

  useEffect(() => {
    if (!consent) setIsOpenConsentDialog(true)
  }, [consent])

  const onAddCameraSuccess = (message, camera) => {
    dispatch(messageActions.showMessage(message))
    camerasActions.get()
    if (cameraHasFreeTrial(camera)) {
      openFreeTrialActivationModal(camera)
    }
    setCameraId(camera?.id)
    dialogActions.open()
  }

  const toggleAddCamera = () => {
    if (cameraActivationEnabled) {
      history.push({
        pathname: '/add-camera',
        state: {
          data: true,
        },
      })
    }
    setIsOpenAddCameraDialog(!isOpenAddCameraDialog)
  }
  const closeInitialAddCamera = () => {
    setInitialAddCameraDialog(false)
  }

  const renderCameras = () => {
    if (FetchingSharedCameras || FetchingPendingSharedCameras || !cameras) {
      return (
        <Paper className={classes.paper} elevation={1}>
          <div className=" center-align">{ t('camera_list.loading') }</div>
        </Paper>
      )
    }

    return (
      <Paper className={classes.paper} elevation={1}>
        { initialAddCameraDialog && cameras.length === 0 && (
          <NewCameraForm onClose={closeInitialAddCamera} onAddCameraSuccess={onAddCameraSuccess} />
        ) }
        { cameras.map((camera) => (
          <CameraTile isSpypoint key={camera.id} camera={camera} />
        )) }
        <ActivateCameraTile toggleAddCamera={toggleAddCamera} displayText={false} isNewUi isSpypoint />
      </Paper>
    )
  }

  return (
    <div className={classes.root}>
      <CAppBar Icon={BigCheckIcon} title={pageTitle} titleClassName={classes.title} iconClassName={classes.icon}>
        <CButton variety="secondary" color="primary" className={classes.addButton} onClick={toggleAddCamera}>
          { t('app:active_device.activate_camera') }
        </CButton>
      </CAppBar>

      { renderCameras() }
      { isOpenAddCameraDialog && <NewCameraForm onClose={toggleAddCamera} onAddCameraSuccess={onAddCameraSuccess} /> }
      { isOpenConsentDialog && <ConsentFormDialog onClose={() => setIsOpenConsentDialog(false)} /> }
      <SpypointPlansDialog
        open={dialogActions.isOpen}
        title={t('app:active_device.plans_dialog_header')}
        text={t('app:active_device.plans_dialog_subheader')}
        confirmText={t('app:active_device.plans_confirm_button')}
        cancelText={t('app:active_device.plans_cancel_button')}
        onClose={dialogActions.close}
        onCancel={dialogActions.close}
        onConfirm={returnToPlans}
      />
    </div>
  )
}

export default CameraListPanel
