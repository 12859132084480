import { createContext, useState, useContext, useEffect } from 'react'
import { createBasket, addItemToBasket, fetchBaskets, deleteItemBasket, navigateToCart } from './ManageBasket'

const BasketContext = createContext({})

export const useBasket = () => useContext(BasketContext)

export const BasketProvider = ({ children }) => {
  const [basket, setBasket] = useState('')
  const [basketDetails, setBasketDetails] = useState([])

  useEffect(() => {
    const storedBasketId = localStorage.getItem('basketId')
    if (storedBasketId) {
      setBasket(storedBasketId)
    }
  }, [])

  const createNewBasket = async () => {
    const newBasket = await createBasket()
    setBasket(newBasket?.basketId)
    localStorage.setItem('basketId', newBasket?.basketId)
    return newBasket
  }

  const addProductToBasket = async (product) => {
    if (!basket || basket === 'undefined') {
      const newBasket = await createNewBasket()
      if (newBasket?.basketId) {
        await addItemToBasket(newBasket.basketId, product)
        localStorage.setItem('basketId', newBasket.basketId)
      }
    } else {
      await addItemToBasket(basket, product)
    }
  }

  const fetchBasket = async (basket) => {
    const existingBasket = await fetchBaskets(basket)
    setBasketDetails(existingBasket)
  }

  const removeItemFromBasket = async (basket, itemId) => {
    await deleteItemBasket(basket, itemId)
  }

  return (
    <BasketContext.Provider value={{ basket, addProductToBasket, fetchBasket, basketDetails, removeItemFromBasket, navigateToCart }}>
      { children }
    </BasketContext.Provider>
  )
}
