import { useEffect } from 'react'
import SpypointBatteryTypeTile from './tiles/SpypointBatteryTypeTile'
import SpypointCellularSignalTile from './tiles/SpypointCellularSignalTile'
import SpypointTemperatureTile from './tiles/SpypointTemperatureTile'
import SpypointMainBatteryTile from './tiles/SpypointMainBatteryTile'
import SpypointSolarBatteryTile from './tiles/SpypointSolarBatteryTile'
import SpypointMemoryTile from './tiles/SpypointMemoryTile'
import SpypointFirstBatteryTile from './tiles/SpypointFirstBatteryTile'
import LastUpdateTile from 'camera-module/camera/ui/camera-status-page/tiles/LastUpdateTile'
import SharedCameraTile from 'camera-module/camera/ui/camera-status-page/tiles/SharedCameraTile'
import ModelTile from 'camera-module/camera/ui/camera-status-page/tiles/ModelTile'
import ModemFirmwareTile from 'camera-module/camera/ui/camera-status-page/tiles/ModemFirmwareTile'
import VersionTile from 'camera-module/camera/ui/camera-status-page/tiles/VersionTile'
import ActivationCodeTile from 'camera-module/camera/ui/camera-status-page/tiles/ActivationCodeTile'
import TimeSynchronizedTile from 'camera-module/camera/ui/camera-status-page/tiles/TimeSynchronizedTile'
import PowerModeTile from 'camera-module/camera/ui/camera-status-page/tiles/PowerModeTile'
import CoordinatesTile from 'camera-module/camera/ui/camera-status-page/tiles/CoordinatesTile'
import UpdateTile from 'camera-module/camera/ui/camera-status-page/tiles/UpdateTile'
import SpypointVBatteryTile from 'camera-module/camera/ui/camera-status-page/tiles/SpypointVBatteryTile'
import useOwnedSharedCameras from 'camera-module/camera/core/useOwnedSharedCameras'
import { useDispatch } from 'react-redux'
import cameraSharingActions from 'camera-module/camera/core/CameraSharingActions'
import { useTranslation } from 'react-i18next'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import { uniqBy } from 'lodash'

let tilesMap
const checkCameraModelFlex = (model) => model?.includes('FLEX') || model.includes('FLEX ')
const checkCameraModelFlexS = (model) => model?.includes('FLEX-S')
const checkCameraModelLinkMicroS = (model) => model?.includes('LINK-MICRO-S')

const SpypointCameraStatusTiles = ({ camera, user }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shared = JSON.parse(localStorage.getItem('isShared'))
  const configEnabled = (configName) => {
    switch (configName) {
      case 'coordinates':
        return camera.config.gps !== false
      default:
        return true
    }
  }
  const updateEnabled =
    camera.status.update && Object.keys(camera.status.update).length !== 0 && !camera.status.update.disabled
  const getLabel = (key) => {
    if (camera.status.model !== 'FLEX') return null
    if (key === 'version') {
      return 'camera.flex.version'
    } else if (key === 'modemFirmware') {
      return 'camera.flex.modem_firmware'
    } else {
      return null
    }
  }
  const ownedSharedCameras = useOwnedSharedCameras()
  const cameraGuests = () => {
    const sharedCams = []
    const shar = sharedCams.concat.apply([], ownedSharedCameras?.map((ownedSharedCamera) => ownedSharedCamera?.sharedCameras))
    const uniqueSharedCameras = uniqBy(shar, 'cameraId').map(x => {
      const guests = ownedSharedCameras.filter(ownedSharedCamera => {
        const ownedSharedCameraFiltred = ownedSharedCamera.sharedCameras.map(x => x.cameraId)
        return ownedSharedCameraFiltred.includes(x.cameraId)
      })
      return { ...x, status: { model: x.model }, guests: guests }
    })
    return uniqueSharedCameras
  }
  const cameraGuest = cameraGuests()
  console.log(cameraGuest.filter((guest) => guest?.cameraId === camera?.id))
  const guestsNumbers = cameraGuest.filter((guest) => guest?.cameraId === camera?.id)[0]?.guests?.length || 0
  useEffect(() => {
    dispatch(cameraSharingActions.fetchOwnedSharedCameras())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const batteryIconsEnabled = appConfig.batteryIcons.switch === switches.on
  if (shared && camera.status?.powerSources?.length > 0 && batteryIconsEnabled) {
    tilesMap = {
      model: ModelTile,
      powerMode: PowerModeTile,
      lastUpdate: LastUpdateTile,
      firstBattery: SpypointFirstBatteryTile,
      secondBattery: SpypointFirstBatteryTile,
      thirdBattery: SpypointFirstBatteryTile,
      signal: SpypointCellularSignalTile,
      memory: SpypointMemoryTile,
      temperature: SpypointTemperatureTile,
      timeSynchronized: TimeSynchronizedTile,
      sharedCamera: SharedCameraTile,
    }
  } else if (camera.status?.powerSources?.length > 0 && batteryIconsEnabled) {
    tilesMap = {
      model: ModelTile,
      powerMode: PowerModeTile,
      version: VersionTile,
      lastUpdate: LastUpdateTile,
      firstBattery: SpypointFirstBatteryTile,
      secondBattery: SpypointFirstBatteryTile,
      thirdBattery: SpypointFirstBatteryTile,
      signal: SpypointCellularSignalTile,
      memory: SpypointMemoryTile,
      temperature: SpypointTemperatureTile,
      modemFirmware: ModemFirmwareTile,
      activationCode: ActivationCodeTile,
      coordinates: CoordinatesTile,
      timeSynchronized: TimeSynchronizedTile,
      sharedCamera: SharedCameraTile,
    }
  } else {
    tilesMap = {
      model: ModelTile,
      powerMode: PowerModeTile,
      version: VersionTile,
      lastUpdate: LastUpdateTile,
      mainBattery: SpypointMainBatteryTile,
      twelVeBattery: SpypointVBatteryTile,
      solarBattery: SpypointSolarBatteryTile,
      batteryType: SpypointBatteryTypeTile,
      signal: SpypointCellularSignalTile,
      memory: SpypointMemoryTile,
      temperature: SpypointTemperatureTile,
      modemFirmware: ModemFirmwareTile,
      activationCode: ActivationCodeTile,
      coordinates: CoordinatesTile,
      timeSynchronized: TimeSynchronizedTile,
      sharedCamera: SharedCameraTile,
    }
  }
  return Object.keys(tilesMap)
    .filter(configEnabled)
    .map((key) => {
      const Tile = tilesMap[key]
      if (key === 'version' && updateEnabled) {
        return (
          <UpdateTile
            key={key}
            value={camera.status[key]}
            disable={!!camera.status?.permanentlyShutdown}
            update={camera.status.update}
          />
        )
      }
      if (key === 'sharedCamera' && !shared && camera.status?.lastUpdate !== undefined) {
        return (
          <SharedCameraTile
            key={key}
            value={guestsNumbers}
            isShared
          />
        )
      }
      if (camera.status?.powerSources?.length > 0) {
        if (key === 'firstBattery') {
          return (
            <SpypointFirstBatteryTile
              key={key}
              type={camera?.status?.powerSources[0]?.type}
              label={t('app:camera.battery_1', { type: camera?.status?.powerSources[0]?.type })}
              value={camera?.status?.powerSources[0]?.percentage}
              activePowerSource={camera?.status?.activePowerSource === 0}
              voltage={camera?.status?.powerSources[0]?.voltage}
            />
          )
        }
      }
      if (camera.status?.powerSources?.length > 1) {
        if (key === 'secondBattery') {
          return (
            <SpypointFirstBatteryTile
              key={key}
              type={camera?.status?.powerSources[1]?.type}
              label={t('app:camera.battery_2', { type: camera?.status?.powerSources[1]?.type })}
              value={camera?.status?.powerSources[1]?.percentage}
              activePowerSource={camera?.status?.activePowerSource === 1}
              voltage={camera?.status?.powerSources[1]?.voltage}
            />
          )
        }
      }
      if (camera.status?.powerSources?.length === 3) {
        if (key === 'thirdBattery') {
          return (
            <SpypointFirstBatteryTile
              key={key}
              type={camera?.status?.powerSources[2]?.type}
              label={t('app:camera.battery_3', { type: camera?.status?.powerSources[2]?.type })}
              value={camera?.status?.powerSources[2]?.percentage}
              activePowerSource={camera?.status?.activePowerSource === 2}
              voltage={camera?.status?.powerSources[2]?.voltage}
            />
          )
        }
      }
      if (camera.status?.batteries?.length > 0) {
        if (key === 'twelVeBattery' && camera.status.batteries.length >= 2 && camera.status.batteryType === '12V' && checkCameraModelFlex(camera.status.model)) {
          return (
            <SpypointVBatteryTile
              key={key}
              value={{ percentage: camera.status.batteries[1] }}
              disable={!!camera.status?.permanentlyShutdown}
            />
          )
        }
        if (key === 'mainBattery') {
          if (camera.status.batteries.length === 3 && camera.status.batteryType === '12V') {
            return null
          }
          if (!checkCameraModelLinkMicroS(camera.status.model)) {
            return (
              <Tile
                key={key}
                value={{ percentage: camera.status.batteries[0] }}
                disable={!!camera.status?.permanentlyShutdown}
                label={getLabel(key)}
              />
            )
          } else return null
        }

        if (key === 'solarBattery') {
          if (camera.status.batteries?.length === 1 && checkCameraModelLinkMicroS(camera.status.model)) {
            return (
              <SpypointSolarBatteryTile
                key={key}
                value={{ percentage: camera.status.batteries[0] }}
                disable={!!camera.status?.permanentlyShutdown}
                label={getLabel(key)}
              />
            )
          }
          if (camera.status.batteries?.length === 3 && checkCameraModelFlexS(camera.status.model)) {
            return (
              <SpypointSolarBatteryTile
                key={key}
                value={{ percentage: camera.status.batteries[2] }}
                disable={!!camera.status?.permanentlyShutdown}
                label={getLabel(key)}
              />
            )
          }
          if (camera.status.batteries?.length === 2 && !checkCameraModelFlex(camera.status.model)) {
            return (
              <SpypointSolarBatteryTile
                key={key}
                value={{ percentage: camera.status.batteries[1] }}
                disable={!!camera.status?.permanentlyShutdown}
                label={getLabel(key)}
              />
            )
          }
          return null
        }
      }
      return (camera.status[key] !== null && camera.status[key] !== undefined) && (
        <Tile
          key={key}
          user={user}
          camera={camera}
          value={camera.status[key]}
          disable={!!camera.status?.permanentlyShutdown}
          label={getLabel(key)}
        />
      )
    })
}

export default SpypointCameraStatusTiles
