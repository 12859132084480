import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import SubmitButton from 'form-module/ui/inputs/SubmitButton'
import Input from 'form-module/ui/inputs/Input'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useReloadCameras } from '../../../../cameras/core/useCameras'
import CameraToggleItem from './CameraToggleItem'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import { useDispatch } from 'react-redux'
import cameraSharingActions from '../../../core/CameraSharingActions'
import formatFormData from './GuestFormHelper'
import FormDisclaimer from './Disclamers/FormDisclaimer'
import CameraDisclaimer from './Disclamers/CameraDisclaimer'
import WarningBox from '../WarningBox/WarningBox'
import cameraSharingApi from '../../../api/cameraSharingApi'
import messageActions from 'shared-module/message/messageActions'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useLanguage from 'shared-module/components/language/useLanguage'
import { useEffect, useState } from 'react'
import AlertBox from '../AlertBox/AlertBox'
import CircleAlert from 'assets/icons/CircleAlert'
import CameraSharingIcon from 'assets/icons/navigation/cameraSharingIcon'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #464444',
    backgroundColor: '#0E0B0B',
  },
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.background.paper} ${theme.palette.background.default}`,
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.default,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.background.paper,
    },
  },
  title: {
    fontFamily: 'Oswald-regular',
    fontSize: '26px',
    marginTop: '12px',
    fontWeight: 500,
    lineHeight: '29.9px',
    textAlign: 'center',
    color: '#F5F4F4',

  },
  paragraph: {
    fontFamily: theme.typography.body1?.fontFamily,
    fontSize: '16px',
    margin: '20px 36px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      margin: '20px 12px',
    },
  },
  image: {
    height: 'auto',
    marginTop: '21px',
  },
  formTitle: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
  },
  titleInfo: {
    fontFamily: 'Oswald-regular',
    fontSize: '21px',
    lineHeight: '31.5px',
    fontWeight: '500',
  },
}))

const GuestForm = ({ isUserClub, selectedGuest, setSelectedGuest, hasGuest }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()
  const [maxSharingCam, setMaxSharingCam] = useState(false)
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('sm'))
  const cameras = useReloadCameras()
  const dialogActions = useConfirmationDialogActions()
  const sendInviteDialogActions = useConfirmationDialogActions()
  const learnMoreDialogActions = useConfirmationDialogActions()
  const language = useLanguage()
  const isFrench = language === 'fr'

  useEffect(() => {
    setMaxSharingCam(false)
  }, [selectedGuest])

  const initialValue = selectedGuest
    ? { name: selectedGuest.guestUsername, email: selectedGuest.guestUserEmail, cameraIds: selectedGuest.sharedCameras.map(camera => camera.cameraId) }
    : { name: '', email: '', cameraIds: [] }

  const GuestFormSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('input.name_required'))
      .matches(/^[a-zA-Z0-9 ]*$/, 'Name can only contain letters and numbers')
      .max(20, 'Name can be at most 20 characters long'),
    email: Yup.string()
      .required(t('input.email_required'))
      .email(t('input.email_invalid')),
  })

  const handleSubmit = (values, { resetForm }) => {
    dispatch(cameraSharingActions.share(formatFormData(values)))
      .then(() => {
        resetForm()
      })
  }

  const formatDate = (data) => {
    const date = new Date(data)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  const removeAccess = () => {
    cameraSharingApi.deleteAccess({ cameraIds: [...selectedGuest?.sharedCameras.map(camera => camera.cameraId)], guestEmail: selectedGuest.guestUserEmail })
      .then(() => {
        dispatch(messageActions.showSuccess(t('camera_sharing.guest_removed')))
        dispatch(cameraSharingActions.fetchOwnedSharedCameras())
        setSelectedGuest(null)
        dialogActions.close()
      })
      .catch(() => dispatch(messageActions.showError()))
  }

  if (!isUserClub) {
    return (
      <Grid container style={{ paddingTop: isMobileResolution ? '0px' : !hasGuest ? '20px' : '50px' }}>
        <Grid container alignItems="center">
          <Box pr={1} mt={1}><CameraSharingIcon /></Box>
          <Box><div className={classes.titleInfo}>{ t('camera_sharing.renew_membership.title') }</div></Box>
        </Grid>
        <Grid container alignItems="center" style={{ fontSize: '16px' }}>
          <Box mt={isMobileResolution ? 2 : 6}>
            <div>
              { !hasGuest ? t('camera_sharing.renew_membership.paragraph_one_join') : t('camera_sharing.renew_membership.paragraph_one') }
            </div>
          </Box>
          <Box mt={3}>
            <div>
              { t('camera_sharing.renew_membership.paragraph_two') }
            </div>
          </Box>
          <Box mt={3}>
            <div>
              &#9989; { t('camera_sharing.renew_membership.accessible_title') }
              <ul>
                <li>{ t('camera_sharing.renew_membership.accessible_list.list1') }</li>
                <li>{ t('camera_sharing.renew_membership.accessible_list.list2') }</li>
                <li>{ t('camera_sharing.renew_membership.accessible_list.list3') }</li>
                <li>{ t('camera_sharing.renew_membership.accessible_list.list4') }</li>
                <li>{ t('camera_sharing.renew_membership.accessible_list.list5') }</li>
              </ul>
            </div>
          </Box>
          <Box mt={1}>
            <div>
              &#x1F512; { t('camera_sharing.renew_membership.inaccessible_title') }
              <ul>
                <li>{ t('camera_sharing.renew_membership.inaccessible_list.list1') }</li>
                <li>{ t('camera_sharing.renew_membership.inaccessible_list.list2') }</li>
                <li>{ t('camera_sharing.renew_membership.inaccessible_list.list3') }</li>
                <li>{ t('camera_sharing.renew_membership.inaccessible_list.list4') }</li>
                <li>{ t('camera_sharing.renew_membership.inaccessible_list.list5') }</li>
              </ul>
            </div>
          </Box>
        </Grid>
        <Box mt={isMobileResolution ? 2 : 3}>
          <SecondaryButton
            onClick={() => {
              history.push('/membership')
            }}
          >
            { !hasGuest ? t('camera_sharing.renew_membership.button_join') : t('camera_sharing.renew_membership.button') }
          </SecondaryButton>
        </Box>
      </Grid>
    )
  }
  return (
    <Box ml={isMobileResolution ? undefined : '56px'} mr={isMobileResolution ? undefined : '43px'} mt="24px" textAlign="left">
      <div data-testid="planDescriptor" className={classes.formTitle}>{ selectedGuest ? t('camera_sharing.edit_guest') : t('camera_sharing.add_guest') }</div>
      { selectedGuest && selectedGuest?.status === 'pending' &&
        (
          <WarningBox
            subText={`${t('camera_sharing.expires_date')}${formatDate(selectedGuest?.expiresAt)}`}
            text={t('camera_sharing.pending_status')}
          />
        ) }
      <Formik
        initialValues={initialValue}
        validationSchema={GuestFormSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        { ({ errors, touched, values, setFieldValue, resetForm }) => {
          return (
            <Form>
              <Field name="name">
                { ({ field }) => (
                  <div>
                    <Input
                      required
                      style={{ marginBottom: '24px', marginTop: '40px', fontSize: '16px', fontFamily: 'ibm-plex-sans-regular' }}
                      name="name"
                      label={t('input.name')}
                      showErrorIcon
                      showInfoIcon
                      disabled={!!selectedGuest || user.isReadOnlyProfile}
                      {...field}
                    />
                  </div>
                ) }
              </Field>
              <Field name="email">
                { ({ field }) => (
                  <div>
                    <Input
                      required
                      name="email"
                      label={t('input.email')}
                      style={{ marginBottom: '24px', fontSize: '16px', fontFamily: 'ibm-plex-sans-regular' }}
                      showErrorIcon
                      showInfoIcon
                      disabled={!!selectedGuest || user.isReadOnlyProfile}
                      {...field}
                    />
                  </div>
                ) }
              </Field>
              <Box mt="0px" mb="40px">
                <FormDisclaimer />
              </Box>
              <Box mb="24px">
                <CameraDisclaimer />
              </Box>
              <Box maxHeight="350px" mb="50px" overflowY="auto" className={classes.container}>
                { cameras?.map((camera) => camera.status?.lastUpdate !== undefined && camera.isCellular && (
                  <CameraToggleItem
                    camera={camera}
                    setMaxSharingCam={setMaxSharingCam}
                    setFieldValue={setFieldValue}
                    cameraIds={values.cameraIds}
                    formValues={values}
                    disabled={selectedGuest?.status === 'pending' || user?.isReadOnlyProfile}
                    editable={selectedGuest?.status === 'accepted'}
                  />
                )) }
              </Box>
              { maxSharingCam && (<AlertBox text={t('camera_sharing.max_guests')} icon={CircleAlert} style={{ width: '100%', marginBottom: '26px', marginTop: '26px' }} />) }
              <Box>
                { !!selectedGuest && (
                  <Grid container direction="row-reverse" alignItems="flex-end" style={{ paddingRight: 0 }}>
                    <Grid item xs={isMobileResolution ? 12 : 6}>
                      <PrimaryButton color="primary" variant="outlined" style={{ width: '100%' }} onClick={dialogActions.open}>
                        { t('camera_sharing.remove_guest') }
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                ) }
                { isMobileResolution
                  ? (
                      !selectedGuest && (
                        <Grid container justifyContent="flex-end">
                          <Grid item xs={12}>
                            <SubmitButton
                              color="primary"
                              variety="primary"
                              type="button"
                              onClick={sendInviteDialogActions.open} // Open the dialog instead of directly submitting the form
                              disabled={values.cameraIds.length === 0 || user.isReadOnlyProfile}
                              style={{ width: '100%' }}
                            >
                              { t('camera_sharing.send_invite') }
                            </SubmitButton>
                          </Grid>
                          <Grid item xs={12}>
                            <PrimaryButton color="primary" variant="outlined" style={{ width: '100%', marginTop: '24px' }} onClick={learnMoreDialogActions.open}>
                              { t('camera_sharing.learn_more') }
                            </PrimaryButton>
                          </Grid>
                        </Grid>
                      )
                    )
                  : !selectedGuest && (
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={isFrench ? 6 : 4} style={{ paddingRight: '8px' }}>
                        <PrimaryButton color="primary" variant="outlined" style={{ width: '100%' }} onClick={learnMoreDialogActions.open}>
                          { t('camera_sharing.learn_more') }
                        </PrimaryButton>
                      </Grid>
                      <Grid item xs={4}>
                        <SubmitButton
                          color="primary"
                          variety="primary"
                          disabled={values.cameraIds.length === 0 || user.isReadOnlyProfile}
                          type="button"
                          onClick={sendInviteDialogActions.open} // Open the dialog instead of directly submitting the form

                          style={{ width: '100%' }}
                        >
                          { t('camera_sharing.send_invite') }
                        </SubmitButton>
                      </Grid>
                    </Grid>
                    ) }

                <ConfirmationDialog
                  isNewUi={false}
                  open={sendInviteDialogActions.isOpen}
                  title={t('camera_sharing.confirmation_invite.title')}
                  text={t('camera_sharing.confirmation_invite.message')}
                  onConfirm={() => {
                    // Submit the form when the "Confirm" button is clicked
                    dispatch(cameraSharingActions.share(formatFormData(values)))
                      .then(() => {
                        resetForm()
                      })
                    sendInviteDialogActions.close()
                  }}
                  onCancel={sendInviteDialogActions.close}
                  confirmText={t('camera_sharing.confirmation_invite.cta_button_send_invite')}
                  cancelText={t('camera_sharing.confirmation_invite.cta_button_cancel')}
                />
              </Box>
            </Form>
          )
        } }
      </Formik>
      <ConfirmationDialog
        isNewUi={false}
        open={dialogActions.isOpen}
        title={t('camera_sharing.confirmation.title')}
        text={t('camera_sharing.confirmation.text')}
        onConfirm={removeAccess}
        onCancel={dialogActions.close}
        confirmText={t('button.confirm')}
        cancelText={t('button.cancel')}
      />
      <ConfirmationDialog
        isNewUi={false}
        open={learnMoreDialogActions.isOpen}
        title={t('camera_sharing.page_title')}
        text={(
          <Grid container alignItems="center" style={{ fontSize: '14px' }}>
            <Box mt={isMobileResolution ? 2 : 6}>
              <div>
                { t('camera_sharing.renew_membership.paragraph_one') }
              </div>
            </Box>
            <Box mt={3}>
              <div>
                { t('camera_sharing.renew_membership.paragraph_two') }
              </div>
            </Box>
            <Box mt={3}>
              <div>
                &#9989; { t('camera_sharing.renew_membership.accessible_title') }
                <ul>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list1') }</li>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list2') }</li>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list3') }</li>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list4') }</li>
                </ul>
              </div>
            </Box>
            <Box mt={1}>
              <div>
                &#x1F512; { t('camera_sharing.renew_membership.inaccessible_title') }
                <ul>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list1') }</li>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list2') }</li>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list3') }</li>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list4') }</li>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list5') }</li>
                </ul>
              </div>
            </Box>
          </Grid>
        )}
        onConfirm={learnMoreDialogActions.close} // Close the dialog when the button is clicked
        confirmText={t('button.close')} // Change the button text to "Close"
      />
    </Box>
  )
}

export default GuestForm
