import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { getPlans } from 'checkout-module/checkout/api/billingApi'
import PlanBlock from 'checkout-module/checkout/ui/transmission-plans-page/PlanBlock'
import { canTheSubscriptionBeUpgraded } from 'checkout-module/checkout/ui/shared/canTheSubscriptionBeUpgraded'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import { siteId } from 'ecommerce-module/config/default'
import shopperApi from 'ecommerce-module/api/shopperApi'
import selector from 'spypoint/src/ecommerce-module/core/cameraPlan.selectors'
import Spinner from 'shared-module/components/Spinner'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  blocks: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: '2',
    maxWidth: '1000px',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}))

const monthlyPeriodType = 'month_by_month'
const annualPeriodType = 'annual'

const PlanList = ({ subscription, planPeriod = 'month_by_month' }) => {
  const classes = useStyles()
  const history = useHistory
  const camera = useSelectedCamera()

  const [plans, setPlans] = useState(null)
  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
  const isReady = localStorage.getItem(`access_token_${siteId}`) !== null

  useEffect(() => {
    !eCommerceEnabled && !plans && getPlans().then(plans => setPlans({ plans: plans }.plans))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Refreshes SF session to fetch plans based on localStorage state
  useEffect(() => {
    if (!eCommerceEnabled) {
      return
    }

    const checkForSalesForceSession = async () => {
      const session = localStorage.getItem(`access_token_${siteId}`)
      window.removeEventListener('storage', checkForSalesForceSession)

      if (session && !plans) {
        await shopperApi
          .getProductsByIds(selector.getAllPlanIDs)
          .then(product => setPlans(product))
      }
    }

    window.addEventListener('storage', checkForSalesForceSession)
    return () => window.removeEventListener('storage', checkForSalesForceSession)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!eCommerceEnabled) {
      return
    }

    isReady && shopperApi
      .getProductsByIds(selector.getAllPlanIDs)
      .then(product => setPlans(product))
  }, [eCommerceEnabled, isReady])

  const buildRedirectParams = plan => {
    const params = new URLSearchParams(this.props.location.search)
    params.append('p', plan.plan.id)
    params.append('f', plan.frequency)

    return params.toString()
  }

  const redirectToPaymentPage = plan => history.push('/camera/' + camera.id + '/subscribe?' + buildRedirectParams(plan))

  const renderBlocks = () => {
    const currentPlan = subscription.plan

    const setPlans = () =>
      subscription?.plan.id === 'Free'
        ? [subscription.plan, ...plans.filter(plan => plan.period === planPeriod)]
        : plans.filter(plan => plan.period === planPeriod)

    const setLegacyPlans = () => {
      if (currentPlan.isActive) {
        return subscription?.plan.id === 'Free'
          ? plans.filter(plan => plan.isActive)
          : plans.filter((plan) => (plan.id === 'Basic' || plan.id === 'Standard' || plan.id === 'Premium'))
      } else return plans.filter(plan => plan.isActive && plan.isFree)
    }

    const activePlans = eCommerceEnabled ? setPlans() : setLegacyPlans()

    return (
      <div className={classes.blocks}>
        { activePlans.map(plan => {
          plan.canUpgradeMonthByMonth = canTheSubscriptionBeUpgraded(subscription, plan, monthlyPeriodType, eCommerceEnabled)
          plan.canUpgradeAnnual = canTheSubscriptionBeUpgraded(subscription, plan, annualPeriodType, eCommerceEnabled)

          return <PlanBlock key={plan.id} plan={plan} subscription={subscription} periodType={planPeriod} onSelectPlan={redirectToPaymentPage} />
        }) }
      </div>
    )
  }

  return (
    <Box className={classes.root}>
      { subscription && plans ? renderBlocks() : <Box mt={2} mb={4}><Spinner /></Box> }
    </Box>
  )
}

export default PlanList
